import { createRouter, createWebHistory } from 'vue-router'
import routes from './router'

const router = createRouter({
  history: createWebHistory(),
  routes
})

export const lastRoute = [{ path: '*', redirect: '/404', hidden: true }]

export default router
