const Login = () => import('@/views/login')
const Project = () => import('@/views/project/list')
const ProjectAdd = () => import('@/views/project/add')
const ProjectEdit = () => import('@/views/project/edit')
const ProjectWatch = () => import('@/views/project/watch')
const ProjectSetUp = () => import('@/views/project/setUp')
const ProjectUsers = () => import('@/views/project/users')
const ProjectRecycle = () => import('@/views/project/recycle')
const ProjectEnter = () => import('@/views/project/enter')
const ProjectInput = () => import('@/views/project/input')
const ProjectData = () => import('@/views/project/data')
const Users = () => import('@/views/users/index')
const UsersAdd = () => import('@/views/users/add')
const Role = () => import('@/views/role/index')
const RoleAdd = () => import('@/views/role/add')

// 需要鉴权
const routes = [
  { path: '/', redirect: '/project' },
  { path: '/project', component: Project },
  { path: '/project/add', component: ProjectAdd },
  { path: '/project/edit', component: ProjectEdit },
  { path: '/project/watch', component: ProjectWatch },
  { path: '/project/setUp', component: ProjectSetUp },
  { path: '/project/users', component: ProjectUsers },
  { path: '/project/recycle', component: ProjectRecycle },
  { path: '/project/enter', component: ProjectEnter },
  { path: '/project/input', component: ProjectInput },
  { path: '/project/data', component: ProjectData },
  { path: '/users/index', component: Users },
  { path: '/users/add', component: UsersAdd },
  { path: '/role/index', component: Role },
  { path: '/role/add', component: RoleAdd },
]

// 不需要鉴权
const constant = [
  { path: '/login', component: Login },
  { path: '/404', component: () => import('@/views/404') },
  { path: '/:pathMatch(.*)', redirect: '/404' }
]

export default [
  ...routes,
  ...constant
]