import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// ant-design
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import '@/styles/common.css'

import IuVideo from 'iu-video'
import 'iu-video/lib/iu-video.css'

import '@/permission' // permission control

import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";

const app = createApp(App)
app.use(router)
app.use(Antd)
app.use(Vue3ColorPicker)
app.mount('#app')
app.use(IuVideo)

